import React, { useState, ChangeEvent } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Container, Box, Button, Typography, Divider, Paper, ThemeProvider } from '@mui/material';
import { Google as GoogleIcon, KeyboardArrowLeft as KeyboardArrowLeftIcon, Facebook as FacebookIcon } from '@mui/icons-material';
import { validateName, validateEmail, validatePassword, validatePhoneNumber, validateFields } from '../Logic/validation';
import SignUpDialog from './SignUpDialog';
import CustomInput from './CustomInput';
import { useRTL } from '../i18n/useRTL';
import { submitUserData } from '../Logic/services';
import './SignUp.css';
import createMyTheme from '../css/theme';
import GeneralDialog from '../Components/GeneralDialog';



const SignUp: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [nextStep, setNextStep] = useState(false);
  const [done, setDone] = useState(false);
  useRTL(element);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    userName: "",
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFirstStep = () => {
    const validationResult = validateFields(fieldsToValidate);

    if (validationResult.isValid) {
      setNextStep(true);
    } else {
      const result = validationResult.messages;
    }
  };

  const fieldsToValidate = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    password: formData.password
  };

  const handleSubmit = () => {
    const validationResult = validateFields(fieldsToValidate);
    if (validationResult.isValid) {

      console.log('Form data submitted:', formData);

      submitUserData(formData).then(response => {
        console.log('User added successfully:', response.data);
        setDone(true);
      })
        .catch(error => {
          console.error('Error adding user:', error);
        });

    } else {
      const result = validationResult.messages;
    }
  };
  const theme = createMyTheme(i18n.dir() as 'ltr' | 'rtl');

  return (
    <div className='signUp'>
      <Container maxWidth="sm">
        <ThemeProvider theme={theme}>
          <Box className="return-button">
            <KeyboardArrowLeftIcon />
            <Typography variant="body2">
              <NavLink to={nextStep ? "/signup" : "/"} className="sign-up" onClick={() => { setNextStep(false) }}>
                {t('SignUp.return_button')}
              </NavLink></Typography>
          </Box>
          <Paper className='form-container-signup' ref={setElement}>
            <Box className='form-title'>
              <Typography variant="h1" gutterBottom>
                One Million Hands
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {t('SignUp.subtitle')}
              </Typography>
            </Box>
            {!nextStep &&
              <>
                <Box
                  className="signup-box"
                  component="form"
                  noValidate
                  autoComplete="off"
                >
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <CustomInput
                      name={"firstName"}
                      label={t('SignUp.first_name_label')}
                      placeholder={t('SignUp.first_name_input')}
                      type="text"
                      value={formData.firstName}
                      onChange={handleInputChange}
                      validate={validateName}
                      sx={{width:150}}
                    />
                    <CustomInput
                      name={"lastName"}
                      label={t('SignUp.last_name_label')}
                      placeholder={t('SignUp.last_name_input')}
                      type="text"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      validate={validateName}
                      sx={{width:150}}

                    />
                  </Box>
                  <CustomInput
                    name={"email"}
                    label={t('SignUp.email_label')}
                    placeholder={t('SignUp.email_input')}
                    type="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    validate={validateEmail} />
                  <CustomInput
                    name={"password"}
                    label={t('SignUp.password_label')}
                    placeholder={t('SignUp.password_input')}
                    type="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    validate={validatePassword} />
                  <Button variant="contained" color="primary" fullWidth onClick={handleFirstStep}>
                    {t('SignUp.signup_button')}
                  </Button>
                </Box>
                {/* <Divider className='divider'>{t('SignUp.divider')}</Divider>
                <Box className="special-signup">
                  <Button variant="outlined" startIcon={<GoogleIcon />} fullWidth >
                    {t('SignUp.google_signup')}
                  </Button>
                  <Button variant="outlined" startIcon={<FacebookIcon />} fullWidth sx={{ mt: 1 }}>
                    {t('SignUp.facebook_signup')}
                  </Button>
                </Box> */}
              </>
            }
            {nextStep &&
              <>
                <Box
                  className="signup-box"
                  component="form"
                  noValidate
                  autoComplete="off"
                >
                  <CustomInput
                    name={"phoneNumber"}
                    label={t('SignUp.phone_label')}
                    placeholder={"05X-XXX-XXXX"}
                    type="tel"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  // validate={validatePhoneNumber}
                  // when clicking enter it submits?
                  />
                  <Typography variant='subtitle1' className='phone-explanation'>
                    {t('SignUp.phone_notice')}
                    <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                      {t('SignUp.finish_signup')}
                    </Button>
                  </Typography>
                </Box>
              </>}
          </Paper>
        </ThemeProvider>
      </Container>
      <GeneralDialog
        open={done}
        titleKey="Dialog.dialog_title"
        contentKey="Dialog.dialog_content"
        buttonTextKey="Dialog.dialog_button"
        buttonLink="/"
      />
    </div>
  );
};

export default SignUp;