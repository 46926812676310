import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormControl, Select, MenuItem, ListItemIcon, ThemeProvider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./MyAccountNav.css";
import theme from "./LanguageSwitcherTheme";
import { useAuth } from "../Router/AuthContext";
import { useNavigate } from "react-router-dom";

const MyAccountNav: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [toggleTitle, setToggleTitle] = useState(true);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleEditProfileClick = () => {
    navigate('/edit-profile');
  };
  return isAuthenticated ? (
    < div >
      <ThemeProvider theme={theme}>
        <FormControl fullWidth className="formControl MyAccountSelect" >
          <Select labelId="language-select-label" id="language-select" value={t("MyAccount.small_title")} className="noBorderSelect MyAccountSelect" IconComponent={ExpandMoreIcon} >
            <MenuItem className="profileMenu" style={{ display: "none" }} value={t("MyAccount.small_title")}>
              {t("MyAccount.small_title")}
            </MenuItem>
            <MenuItem className="profileMenu text-direction" id="EditProfile" value={t("MyAccount.subtitle_1")}  onClick={handleEditProfileClick}>
              {t("MyAccount.subtitle_1")}
            </MenuItem>
            <hr />
            <MenuItem className="profileMenu" id="SharedPostsNav" value={t("MyAccount.subtitle_2")}>
              <div className="account-option-text">
                <ListItemIcon className="Icon"><img className='IsraelFlag' src='/israel_flag.png' /></ListItemIcon>
                {t("MyAccount.subtitle_2")}
              </div>
              <div className="SharedPostsBadge" color="primary">
                124
              </div>
            </MenuItem>
            <MenuItem className="profileMenu" id="ReportedPostsNav" value={t("MyAccount.subtitle_3")}>
              <div className="account-option-text">
                <ListItemIcon className="Icon">⚠️</ListItemIcon>
                {t("MyAccount.subtitle_3")}
              </div>
              <div className="ReportedPostBadge" color="primary">
                43
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      </ThemeProvider>
    </div >
  ) : (
    <></>
  );
};

export default MyAccountNav;
