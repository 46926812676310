import React, { useEffect } from 'react';
import './css/App.css';
import Header from './Header/Header'
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './Router/AppRoutes';
import { AuthProvider } from './Router/AuthContext';
import Tour from './Tour/Tour';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Tour/>
          <Header />
          <div className='app-content'>
            <AppRoutes />
          </div>
        </div>
      </Router>
    </AuthProvider>

  );
}

export default App;
