// Validate name: English only, two words, at least two letters each
export const validateName = (name: string): { isValid: boolean; message: string } => {
    const trimmedName = name.trim();
    const regex = /^[A-Za-z]{2,}$/;
    if (!regex.test(trimmedName)) {
        return { isValid: false, message: "validation.nameInvalid" };
    }
    return { isValid: true, message: "" };
};

// Validate email: correct email format
export const validateEmail = (email: string): { isValid: boolean; message: string } => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(email)) {
        return { isValid: false, message: "validation.emailInvalid" };
    }
    return { isValid: true, message: "" };
};

// Validate password: check if it's a safe password
export const validatePassword = (password: string): { isValid: boolean; message: string } => {
    // Example: At least 8 characters, at least one letter and one number
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/;
    
    if (!regex.test(password)) {
        return { isValid: false, message: "validation.passwordInvalid" };
    }
    return { isValid: true, message: "" };
};

// Validate phone number: Israeli phone number
export const validatePhoneNumber = (phone: string): { isValid: boolean; message: string } => {
    // Israeli phone number pattern
    const regex = /^(?:\+972|972|0)(?:[23489]|5[01234589]|7[234679]|77)[1-9]\d{6}$/;
    
    // Validate the length to ensure the total digits are correct
    const phoneDigitsOnly = phone.replace(/[^\d]/g, ""); // Remove non-digit characters
    if ((phone.startsWith("+972") && phoneDigitsOnly.length !== 12) ||
        (phone.startsWith("972") && phoneDigitsOnly.length !== 11) ||
        (phone.startsWith("0") && phoneDigitsOnly.length !== 10)) {
        return { isValid: false, message: "validation.phoneInvalid" };
    }
    
    if (!regex.test(phone)) {
        return { isValid: false, message: "validation.phoneInvalid" };
    }
    return { isValid: true, message: "" };
};

type ValidationKey = 'firstName' | 'lastName' | 'email' | 'password' | 'phone';

type ValidationFunctionMap = {
    [key in ValidationKey]: (input: string) => { isValid: boolean; message: string };
};

const validationFunctions: ValidationFunctionMap = {
    firstName: validateName,
    lastName: validateName,
    email: validateEmail,
    password: validatePassword,
    phone: validatePhoneNumber
};

type FieldValues = {
    [key in ValidationKey]?: string;
};

export const validateFields = (fields: FieldValues) => {
    let isValid = true;
    let messages: string[] = [];

    (Object.keys(fields) as ValidationKey[]).forEach(key => {
        const value = fields[key];
        if (value !== undefined) {
            const { isValid: fieldIsValid, message } = validationFunctions[key](value);
            if (!fieldIsValid) {
                isValid = false;
                messages.push(message);
            }
        }
    });

    return { isValid, messages };
};