import React, { useState, ChangeEvent } from 'react';
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Container, Box, Button, Typography, Paper, ThemeProvider } from '@mui/material';
import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from '@mui/icons-material';
import { validateName, validateEmail, validatePassword, validatePhoneNumber } from '../Logic/validation';
import CustomInput from './CustomInput';
import { useRTL } from '../i18n/useRTL';
import { submitUserData } from '../Logic/services';
import createMyTheme from '../css/theme';

const EditProfile: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [element, setElement] = useState<HTMLElement | null>(null);
  const [done, setDone] = useState(false);
  useRTL(element);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    phoneNumber: "",
    currentPassword: "",
    newPassword: "",
  });

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    // const validationResult = validateFields({
    //   firstName: formData.firstName,
    //   lastName: formData.lastName,
    //   email: formData.email,
    //   password: formData.newPassword
    // });

    // if (validationResult.isValid) {
    //   submitUserData(formData).then(response => {
    //     console.log('User added successfully:', response.data);
    //     setDone(true);
    //   }).catch(error => {
    //     console.error('Error adding user:', error);
    //   });
    // }
  };

  const theme = createMyTheme(i18n.dir() as 'ltr' | 'rtl');

  return (
    <div className='signUp'>
      <Container maxWidth="sm">
        <ThemeProvider theme={theme}>
          <Box className="return-button">
            <KeyboardArrowLeftIcon />
            <Typography variant="body2">
              <NavLink to="/" className="sign-up" onClick={() => { }}>
                {t('EditProfile.return_button')}
              </NavLink>
            </Typography>
          </Box>
          <Paper className='form-container-signup' ref={setElement}>
            <Box className='form-title'>
              <Typography variant="h1" gutterBottom>
                {t('EditProfile.title')}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {t('EditProfile.subtitle')}
              </Typography>
            </Box>
            <Box
              className="signup-box"
              component="form"
              noValidate
              autoComplete="off"
            >
              <Box sx={{ display: 'flex', gap: 2 }}>
                <CustomInput
                  name={"firstName"}
                  label={t('EditProfile.first_name_label')}
                  placeholder={t('EditProfile.first_name_input')}
                  type="text"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  validate={validateName}
                  sx={{ width: 150 }}
                />
                <CustomInput
                  name={"lastName"}
                  label={t('EditProfile.last_name_label')}
                  placeholder={t('EditProfile.last_name_input')}
                  type="text"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  validate={validateName}
                  sx={{ width: 150 }}
                />
              </Box>
              <CustomInput
                name={"email"}
                label={t('EditProfile.email_label')}
                placeholder={t('EditProfile.email_input')}
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                validate={validateEmail}
              />
              <CustomInput
                name={"userName"}
                label={t('EditProfile.username_label')}
                placeholder={t('EditProfile.username_input')}
                type="text"
                value={formData.userName}
                onChange={handleInputChange}
                validate={validateName}
              />
              <CustomInput
                name={"phoneNumber"}
                label={t('EditProfile.phone_number_label')}
                placeholder={t('EditProfile.phone_number_input')}
                type="text"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                validate={validatePhoneNumber}
              />
              <CustomInput
                name={"currentPassword"}
                label={t('EditProfile.current_password_label')}
                placeholder={t('EditProfile.current_password_input')}
                type="password"
                value={formData.currentPassword}
                onChange={handleInputChange}
                validate={validatePassword}
              />
              <CustomInput
                name={"newPassword"}
                label={t('EditProfile.new_password_label')}
                placeholder={t('EditProfile.new_password_input')}
                type="password"
                value={formData.newPassword}
                onChange={handleInputChange}
                validate={validatePassword}
              />
              <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
                {t('EditProfile.save_button')}
              </Button>
            </Box>
          </Paper>
        </ThemeProvider>
      </Container>
    </div>
  );
};

export default EditProfile;