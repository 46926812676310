import React from 'react';
import i18n from 'i18next';

export function getLanguageDisplayName(code: string, locale: string): string {
  const displayNames = new Intl.DisplayNames([locale], { type: 'language' });
  const displayName = displayNames.of(code);
  return displayName || code; 
}
/**
 * Creates an array of React elements representing paragraphs and line breaks.
 * 
 * This function takes a string and returns a React fragment. It splits the text into paragraphs and lines.
 * Paragraphs are separated by two newline characters ("\n\n"), and lines within paragraphs are separated by
 * a single newline character ("\n"). It uses `<p>` tags to separate paragraphs and `<br>` tags for line breaks.
 *
 * @param {string} text - The text to be converted into paragraphs and line breaks.
 * @returns {JSX.Element} A React fragment containing the formatted text as a series of paragraphs and line breaks.
 */
export const createParagraphsAndLineBreaks = (text: string): JSX.Element => {
  const paragraphs = text.split("\n\n").map((paragraph, pIndex) => (
    <React.Fragment key={`paragraph-${pIndex}-${paragraph.substring(0, 30)}`}>
      {paragraph.split("\n").map((line, lIndex) => (
        <React.Fragment key={`line-${pIndex}-${lIndex}-${line.substring(0, 30)}`}>
          {line}
          {lIndex !== paragraph.split("\n").length - 1 && <br />}
        </React.Fragment>
      ))}
      {pIndex !== text.split("\n\n").length - 1 && <p />}
    </React.Fragment>
  ));

  return <>{paragraphs}</>;
};


function setLanguageDirection() {
  const direction = i18n.dir();
  document.documentElement.style.setProperty('--text-direction', direction);
}
i18n.on('languageChanged', setLanguageDirection);
setLanguageDirection();