import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { MAIN_ROUTE } from './AppRoutes';

interface PublicComponentProps {
    element: React.ElementType;
}

const PublicComponent: React.FC<PublicComponentProps> = ({ element: Component }) => {
    const { isAuthenticated } = useAuth();

    if (isAuthenticated) {
        return <Navigate to={MAIN_ROUTE} />;
    }

    return <Component />;
};  

export default PublicComponent;
