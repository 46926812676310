import React, { useEffect, useState } from 'react';
import { Grid, Switch, ThemeProvider, Typography } from '@mui/material';
import CustomTooltip from '../../../Components/CustomTooltip';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../Zustand/store';
import createMyTheme from '../../../css/theme';

const ModeSwitch: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [isSwitchOn, setIsSwitchOn] = useState(true);
    const setProIsrael = useStore((state) => state.setProIsrael);

    const switchLabelClassName = isSwitchOn ? 'switch-label' : 'switch-label-red';
    const oppositeSwitchLabelClassName = isSwitchOn ? '' : 'switch-label';
    const tooltipTitle = t(`Navigation.Switch.${isSwitchOn ? 'shareToolTip' : 'reportToolTip'}`);


    const handleSwitchChange = () => {
        setIsSwitchOn(!isSwitchOn);
    };

    useEffect(() => {
        setProIsrael(isSwitchOn);
    }, [isSwitchOn]);

    const theme = createMyTheme(i18n.dir() as 'ltr' | 'rtl');

    return (
        <ThemeProvider theme={theme}>
            <Grid component="label" container alignItems="center" spacing={1} >
                <Grid item>
                    <Typography className={oppositeSwitchLabelClassName} variant='subtitle2'>{t('Navigation.Switch.shareLabel')}</Typography>
                </Grid>
                <Grid item>
                    <CustomTooltip title={tooltipTitle}>
                        <div>
                            <Switch
                                checked={isSwitchOn}
                                onChange={handleSwitchChange}
                                value="isSwitchOn"
                            />
                        </div>
                    </CustomTooltip>
                </Grid>
                <Grid item>
                    <Typography className={switchLabelClassName} variant='subtitle2'>{t('Navigation.Switch.reportLabel')}</Typography>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}

export default ModeSwitch;
