import React, { useContext, useEffect } from 'react';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { useStore } from '../Zustand/store';
import { joyrideSteps } from './tourSteps';
import CustomTooltip from './CustomTooltip';
import { joyrideOutline } from './TourStyles';

interface TourProps {
    continuous?: boolean;
    showSkipButton?: boolean;
}

const Tour: React.FC<TourProps> = ({ continuous = true, showSkipButton = true }) => {
    const { joyrideRun, stopTour } = useStore(state => ({
        joyrideRun: state.joyrideRun,
        stopTour: state.stopTour
    }));

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status } = data;
        const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

        if (finishedStatuses.includes(status)) {
            stopTour();
        }
    };

    return (
        <Joyride
            run={joyrideRun}
            steps={joyrideSteps}
            continuous={continuous}
            showSkipButton={showSkipButton}
            styles={joyrideOutline}
            tooltipComponent={(props) => <CustomTooltip {...props} totalSteps={joyrideSteps.length} />}
            callback={handleJoyrideCallback}
        />
    );
};

export default Tour;