import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Card, CardMedia, CardContent, CardHeader, CardActions, Avatar, IconButton, Typography, Grow } from '@mui/material';
import { Report as ReportIcon, Favorite as FavoriteIcon, Share as ShareIcon } from '@mui/icons-material';
import { formatDistanceToNowStrict } from 'date-fns';
import { ExtendedPostProps } from './PostProps';
import CustomTooltip from '../Components/CustomTooltip';
import { useTranslation } from 'react-i18next';
import { getFirstAlphabetLetter, useContentToggle } from '../i18n/useRTL';
import { cardHeaderDate, cardHeaderUsername } from '../css/theme';

import '../css/Post.css';
import { getImage } from '../Logic/services';


const Post: React.FC<ExtendedPostProps> = (props) => {
    const { t } = useTranslation();
    const { isContentLong, isTextExpanded, setTextExpanded, textRef } = useContentToggle();
    const {postId, post_url, userName, date, likeCount, contentText, imageUrl, pro_israel, onShareClick } = props;
    const defaultImageUrl = "/errorimg.png";
    const [imageBlob, setImageBlob] = useState<Blob | null>(null);
    var numeral = require('numeral');
    const likes = numeral(likeCount).format((likeCount ? likeCount : 0) >= 1000 ? '0.0a' : '0a');
    const timeElapsed = date ? formatDistanceToNowStrict(new Date(date))
        : 'UD';

    const avatarLabel = getFirstAlphabetLetter(userName);
    const mainContent = contentText;
    const cardClass = `Post ${pro_israel ? 'proIsrael' : 'antiIsrael'}`;
    const avatarClass = pro_israel ? 'avatarBlue' : 'avatarRed';

    const handleLikeClick = () => {
        window.open(post_url, '_blank')
    }
    const delay = props.index ? props.index * 100 : 0; // Example: 100ms delay for each subsequent post

    const fetchImage = async () => {
        try {
            const response = await getImage(postId);
            setImageBlob(new Blob(response.data));
            console.log("fetched image for", postId)
        } catch (error) {
            console.error('Failed to fetch image:', error);
        }
    };
    useEffect(() => {
        if (postId) {
            fetchImage();
        }
    }, [postId]);

    function handleImageError(e: SyntheticEvent<HTMLImageElement, Event>): void {
        const target = e.target as HTMLImageElement;
        target.onerror = null;
        target.src = defaultImageUrl;
    }

    return (
        <Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={delay}>
            <Card sx={{ maxWidth: 345 }} className={cardClass}>
                {/*
                Use this for old header style
                <CardHeader
                    avatar={
                        <Avatar className={avatarClass} aria-label="user avatar">
                            {avatarLabel}
                        </Avatar>
                    }
                    title={userName}
                    subheader={date ?? 'Unknown Date'}
                /> */}
                <CardMedia
                    component="img"
                    height="194"
                    src={imageBlob? URL.createObjectURL(imageBlob) : imageUrl ? imageUrl : defaultImageUrl}
                    onError={handleImageError}
                />
                <CardContent className='content-card'>
                    <div className='card-user-info'>
                        <Avatar className={avatarClass} aria-label="user avatar" sx={{ width: 24, height: 24 }}>
                            <Typography variant='overline'>{avatarLabel}</Typography>
                        </Avatar>
                        <Typography sx={cardHeaderUsername}>{userName}</Typography>
                        <Typography sx={cardHeaderDate}>• {timeElapsed}</Typography>
                        <Typography style={{ marginLeft: 'auto' }} sx={cardHeaderDate}>{likes ?? 0} likes</Typography>
                    </div>
                    <div>
                        <Typography variant="body2" className={isTextExpanded ? "content-text-full" : "content-text-limited"} ref={textRef} >
                            <br />
                            {mainContent}
                        </Typography>
                        {isContentLong && (
                            <Typography variant="body2" color="text.secondary" onClick={() => setTextExpanded(!isTextExpanded)}>
                                {isTextExpanded ? t("Post.readLess") : t("Post.readMore")}
                            </Typography>
                        )}
                    </div>
                </CardContent>
                <CardActions disableSpacing className='icon-bar'>
                    <CustomTooltip title={t("Post.like")}>
                        <IconButton aria-label="add to favorites" onClick={handleLikeClick}>
                            {pro_israel ? <FavoriteIcon /> : <ReportIcon />}
                        </IconButton>
                    </CustomTooltip>
                    {pro_israel && onShareClick && (
                        <CustomTooltip title={t("Post.share")}>
                            <IconButton aria-label="share" onClick={() => onShareClick(props)}>
                                <ShareIcon />
                            </IconButton>
                        </CustomTooltip>
                    )}
                </CardActions>
            </Card>
        </Grow>
    );
};

export default Post;