import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Container, Box, Button, Typography, Paper, ThemeProvider } from '@mui/material';
import CustomInput from './CustomInput';
import { useRTL } from '../i18n/useRTL';
import { validateEmail } from '../Logic/validation';
import { submitPasswordReset } from '../Logic/services';
import { MAIN_ROUTE } from '../Router/AppRoutes';
import "./SignIn.css"
import { useAxios } from '../Logic/Hooks/useAxios';
import createMyTheme from '../css/theme';
import GeneralDialog from '../Components/GeneralDialog';

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState("");
    const { execute, isLoading, response, error } = useAxios(submitPasswordReset);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [element, setElement] = useState<HTMLElement | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    useRTL(element);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!validateEmail(email)) {
            return;
        }
        execute({ email });
    };

    useEffect(() => {
        if (response !== null) {
            console.log("Password reset email sent");
            navigate(MAIN_ROUTE);
        }
    }, [response, navigate]);

    const theme = createMyTheme(i18n.dir() as 'ltr' | 'rtl');

    return (
        <div>
            <Container maxWidth="sm" ref={setElement}>
                <ThemeProvider theme={theme}>
                    <Paper className='form-container'>
                        <Box className='form-title'>
                            <Typography className='form-title-omh' variant="h1" gutterBottom>
                                One Million Hands
                            </Typography>
                            <Typography className="form-subtitle" variant="subtitle1" gutterBottom>
                                {t('ForgotPassword.subtitle')}
                            </Typography>
                        </Box>
                        <Box
                            className="login-box"
                            component="form"
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                        >
                            <CustomInput
                                name={"email"}
                                label={t('ForgotPassword.email_label')}
                                placeholder={t('ForgotPassword.email_input')}
                                type="email"
                                value={email}
                                onChange={handleInputChange}
                                validate={validateEmail}
                            />
                            <Box sx={{ width: "100%" }}>
                                <Button variant="contained" color="primary" fullWidth type="submit">
                                    {t('ForgotPassword.reset_button')}
                                </Button>
                                <Button variant="contained" color="primary" fullWidth onClick={() => setDialogOpen(true)}>
                                    Completion-test
                                </Button>
                                <Typography
                                    gutterBottom
                                    style={{
                                        color: isLoading ? 'blue' : error ? 'red' : 'green',
                                        textAlign: 'center'
                                    }}>
                                    {isLoading ? t('ForgotPassword.loading_message') : error ? t('ForgotPassword.error_message') : response ? t('ForgotPassword.success_message') : ""}
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="back-to-signin">
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                <NavLink to="/signin" className="signin-link">
                                    {t('ForgotPassword.back_to_signin')}
                                </NavLink>
                            </Typography>
                        </Box>
                    </Paper>
                </ThemeProvider>
            </Container>
            <GeneralDialog
                open={dialogOpen}
                titleKey="ResetDialog.dialog_title"
                contentKey="ResetDialog.dialog_content"
                buttonTextKey="ResetDialog.dialog_button"
                buttonLink="/"
            />
        </div>
    );
};

export default ForgotPassword;
