import { useState, useEffect, useRef  } from 'react';
import { useTranslation } from 'react-i18next';

export const useRTL = (element: HTMLElement | null): void => {
    const { i18n } = useTranslation();

    useEffect(() => {
        if (element) {
            const setDirection = (): void => {
                const direction = i18n.dir();
                element.setAttribute('dir', direction);
            };

            setDirection();
            i18n.on('languageChanged', setDirection);

            return () => {
                i18n.off('languageChanged', setDirection);
            };
        }
    }, [i18n, element]);
};

export const useContentToggle = (initialState = false) => {
    const [isContentLong, setContentLong] = useState(initialState);
    const [isTextExpanded, setTextExpanded] = useState(false);
    const textRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        const checkContentLength = () => {
            if (textRef.current) {
                const elementHeight = textRef.current.offsetHeight;
                const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight, 10);
                const lines = elementHeight / lineHeight;
                setContentLong(lines > 2); // Change 2 to whatever number of lines you want to trigger "Read More" on
            }
        };

        checkContentLength();

        // Optional: Re-check on window resize for responsive designs
        window.addEventListener('resize', checkContentLength);
        return () => window.removeEventListener('resize', checkContentLength);
    }, []);

    return { isContentLong, isTextExpanded, setTextExpanded, textRef };
};

export const getFirstAlphabetLetter = (input: string): string | null => {
    const match = input.match(/[a-zA-Z]/);
    return match ? match[0].toUpperCase() : null;
};
/* example on how to use properly:

  const [element, setElement] = useState<HTMLElement | null>(null);
  useRTL(element); 

  (inside the return statement)
  <div className='About_Us' ref={setElement}>

*/
