import React, { useState } from 'react';
import { TooltipRenderProps } from 'react-joyride';
import Box from '@mui/material/Box'; // Import MUI Box
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { CustomBackButtonStyle, CustomButtonStyle, ToolTipText, customBoxStyle } from './TourStyles';
import { useRTL } from '../i18n/useRTL';
import { createParagraphsAndLineBreaks } from '../i18n/languageUtils';

interface CustomTooltipProps extends TooltipRenderProps {
    totalSteps: number;
}



const CustomTooltip: React.FC<CustomTooltipProps> = ({
    totalSteps,
    backProps,
    continuous,
    index,
    isLastStep,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
}) => {
    const { t } = useTranslation();
    const [element, setElement] = useState<HTMLElement | null>(null);
    useRTL(element);
    
    const getContent = () => (
        step.content === "" ? createParagraphsAndLineBreaks( t("Tour.step" + (index + 1))) : step.content as string
    );
    return (
        <Box {...tooltipProps} sx={customBoxStyle} ref={setElement}>
            <Typography sx={ToolTipText}>
                {getContent()}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <Box>
                    <Typography sx={ToolTipText}>{index + 1}/{totalSteps}</Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    {index > 0 && (
                        <Button sx={CustomBackButtonStyle} {...backProps} variant="contained" color="primary">
                            {t('CustomToolTip.backLabel')}
                        </Button>
                    )}
                    {index === 0 && (
                        <Button sx={CustomBackButtonStyle} {...skipProps} variant="contained" color="primary">
                            {t('CustomToolTip.skipLabel')}
                        </Button>
                    )}
                    <Button sx={CustomButtonStyle} {...primaryProps} variant="contained" color="primary">
                        {!isLastStep ? t('CustomToolTip.nextLabel') : t('CustomToolTip.closeLabel')}
                    </Button>
                </Box>

            </Box>
        </Box>
    );
};

export default CustomTooltip;
