import { useState, useCallback } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';

export function useAxios<T = any, D = any>(
    apiFunc: (data?: D) => Promise<AxiosResponse<T>>
) {
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState<T | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [statusCode, setStatusCode] = useState<number | null>(null);

    const execute = useCallback(async (data?: D) => {
        setIsLoading(true);
        setError(null);
        setStatusCode(null);

        try {
            const result = await apiFunc(data);
            setStatusCode(result.status);

            if (result.status >= 200 && result.status < 300) {
                setResponse(result.data);
            } else {
                setError(`Received status code ${result.status}`);
            }
        } catch (error) {
            const err = error as AxiosError;
            if (err.response) {
                setError(err.response.statusText || err.message);
                setStatusCode(err.response.status);
            } else {
                setError("An unexpected error occurred");
            }
        } finally {
            setIsLoading(false);
        }
    }, [apiFunc]);

    return { execute, isLoading, response, error, statusCode };
}
