import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Typography, DialogActions, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRTL } from '../i18n/useRTL';

interface GeneralDialogProps {
  open: boolean;
  titleKey: string;
  contentKey: string;
  buttonTextKey: string;
  buttonLink: string;
}

const GeneralDialog: React.FC<GeneralDialogProps> = ({ open, titleKey, contentKey, buttonTextKey, buttonLink }) => {
  const { t } = useTranslation();
  const [element, setElement] = useState<HTMLElement | null>(null);
  useRTL(element);

  return (
    <Dialog open={open} ref={setElement}>
      <DialogTitle>
        {t(titleKey)}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography component="span" className='phone-explanation'>
            {t(contentKey)}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button component={NavLink} to={buttonLink}>
          {t(buttonTextKey)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GeneralDialog;
