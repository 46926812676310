import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, SelectChangeEvent, ThemeProvider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getLanguageDisplayName } from '../i18n/languageUtils';
import theme from './LanguageSwitcherTheme';

const LanguageSwitcher: React.FC = () => {
    const { i18n } = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    useEffect(() => {
        setSelectedLanguage(i18n.language);
    }, [i18n.language]);

    const handleLanguageChange = (event: SelectChangeEvent) => {
        const newLanguage = event.target.value as string;
        setSelectedLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    const availableLanguages = Object.keys(i18n.options.resources || {}); // This ensures all your defined languages are listed

    return (
        <ThemeProvider theme={theme}>
            <div>
                <FormControl fullWidth>
                    <Select
                        className='noBorderSelect'
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        IconComponent={ExpandMoreIcon}
                        renderValue={(selected) => selected as string}
                    >
                        {availableLanguages.map(code => (
                            <MenuItem className='languageMenuItem' key={code} value={code}>
                                {code} - {getLanguageDisplayName(code, selectedLanguage)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </ThemeProvider>

    );
};

export default LanguageSwitcher;