import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRTL } from '../i18n/useRTL';

import './AboutUs.css';

const AboutUs: React.FC = () => {
  const { t, i18n } = useTranslation();

  // This part ensures only the the about us page will be RTL
  const [element, setElement] = useState<HTMLElement | null>(null);
  useRTL(element);

  return (
    <div className={`About_Us ${i18n.dir()}`} ref={setElement}>
      <div className='Small_Title'>{t('About_Us.small_title')}</div>
      <div className='Big_Title'>One Million Hands</div>
      <div className='Paragraph'>
        <p>{t('About_Us.first_paragraph')}</p>
        <p>{t('About_Us.second_paragraph')}</p>
        <p>{t('About_Us.third_paragraph')}</p>
      </div>
    </div>
  );
};

export default AboutUs;