import { Step } from 'react-joyride';

//having content as "" will automatically call for the label Tour.step{index}, otherwise, will use the text
export const joyrideSteps: Step[] = [
  {
    target: '.Post',
    content: "",
    title: 'Welcome',
    disableBeacon: true,
    floaterProps: { hideArrow: true, placement: "right-end" },
  },
  {
    target: '.icon-bar',
    content: '',
    title: 'Feature Explanation',
    disableBeacon: true,
    disableScrolling: true,
    floaterProps: { hideArrow: true, placement: "right-end" }
  },
  {
    target: '.modeSwitcher',
    content: '',
    title: 'Feature Explanation',
    disableBeacon: true,
    floaterProps: { hideArrow: true, placement: "left-start" }
  },
  {
    target: '#language-select',
    content: '',
    title: 'Feature Explanation',
    disableBeacon: true,
    floaterProps: { hideArrow: true, placement: "right-end" }
  },
];
