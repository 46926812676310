// store.ts
import { create } from 'zustand';
import { IPostData, PostData } from '../Post/PostData';
import { PostProps } from '../Post/PostProps';
import { FilterCriteria, filterPosts } from '../Post/FilterPosts';

export type PlatformType = "Instagram" | "Facebook" | "Twitter" | "TikTok" | "LinkedIn" | "YouTube";

export interface StoreState {
  isProIsrael: boolean;
  allPosts: PostProps[];
  filteredPosts: PostProps[];
  platform: PlatformType;
  filterCriteria: FilterCriteria;
  setProIsrael: (value: boolean) => void;
  setAllPosts: (posts: PostData[]) => void;
  updateFilteredPosts: () => void;
  updateFilterCriteria: (criteria: Partial<FilterCriteria>) => void;
  setPlatform: (platform: PlatformType) => void;
  getLanguages: () => string[];
  joyrideRun: boolean;
  startTour: () => void;
  stopTour: () => void;
}

export const useStore = create<StoreState>((set, get) => ({
  isProIsrael: true,
  allPosts: [],
  filteredPosts: [],
  platform: "Instagram" as PlatformType,
  filterCriteria: {},
  setProIsrael: (value: boolean) => set(state => {
    const newState = { isProIsrael: value };
    state.updateFilterCriteria({ pro_israel: value });
    return newState;
  }),
  setAllPosts: (posts) => set({ allPosts: posts.map(postData => PostProps.fromPostData(postData)) }),
  updateFilteredPosts: () => {
    const criteria = get().filterCriteria;
    const filtered = filterPosts(get().allPosts, criteria);
    set({ filteredPosts: filtered });
  },
  updateFilterCriteria: (newCriteria) => {
    set((state) => {
      const updatedCriteria = { ...state.filterCriteria, ...newCriteria };
      return { filterCriteria: updatedCriteria };
    });
    get().updateFilteredPosts();
  },
  setPlatform: (platform: PlatformType) => set(state => {
    const newState = { platform };
    state.updateFilterCriteria({ platform });
    return newState;
  }),
  getLanguages: () => {
    const languages = new Set<string>();
    get().allPosts.forEach(post => languages.add(post.language));
    return Array.from(languages);
  },

  // Joyride state
  joyrideRun: false,
  startTour: () => set({ joyrideRun: true }),
  stopTour: () => set({ joyrideRun: false }),
}));
