import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/system';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { menuItemHoverStyle, menuStyle } from '../../../css/theme';
import { useStore } from '../../../Zustand/store';
import TourModal from '../../../Tour/TourModal';
import { useRTL } from '../../../i18n/useRTL';
import { useTranslation } from 'react-i18next';


const StyledIconButton = styled(IconButton)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    gap: '8px',
    position: 'absolute',
    width: '40px',
    height: '40px',
    bottom: '24px',
    background: 'rgba(0, 0, 0, 0.7)',
    boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: '20px',
    color: '#FFFFFF',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.9)', // Slightly darker on hover
    },
});

const HelpButton: React.FC = () => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isTourModalOpen, setTourModalOpen] = useState(false);
    const open = Boolean(anchorEl);
    
    const [element, setElement] = useState<HTMLElement | null>(null);
    useRTL(element);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function handleStartTour() {
        setAnchorEl(null);
        setTourModalOpen(true)
    }

    const handleContactClick = () => {
        window.open('https://t.me/OneMillionHands', '_blank');
        handleClose();
    };
    return (
        <>
            <StyledIconButton
                aria-label="help"
                aria-controls={open ? 'simple-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <QuestionMarkIcon />
            </StyledIconButton>
            <Menu
                ref={setElement}
                id="simple-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                PaperProps={{
                    sx: menuStyle,
                }}
            >
                <MenuItem sx={menuItemHoverStyle} onClick={handleStartTour}>
                    {t('Navigation.HelpButton.tour')}
                </MenuItem>
                <MenuItem sx={menuItemHoverStyle} onClick={handleContactClick}>
                    {t('Navigation.HelpButton.contact')}
                </MenuItem>
            </Menu>
            <TourModal open={isTourModalOpen} onClose={() => setTourModalOpen(false)} />
        </>
    );
}

export default HelpButton;