import React, { useState } from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useRTL } from '../i18n/useRTL';

const ToBeStyledTooltip = ({ className, ...props }: TooltipProps & { className?: string }) => (
    <Tooltip {...props} classes={{ tooltip: className }} />
);

const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    isolation: 'isolate',
    backgroundColor: '#0D0D0E',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '6px',
    padding: '3px 8px',
    '& .MuiTooltip-arrow': {
        color: '#0D0D0E', // Change this to the desired arrow color
    },

    // Typography styles
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '122%',
    textAlign: 'center',
    color: '#FFFFFF',

}));


const CustomTooltip: React.FC<TooltipProps> = (props) => {
    return (
        <StyledTooltip
            {...props}
            disableFocusListener
            disableTouchListener
            disableInteractive
            placement="top"
            arrow={true}
        />
    );
};

export default CustomTooltip;
