import React, { useState } from 'react';
import { Modal, Box, IconButton, Typography, Button } from '@mui/material';
import { omhTitleStyle, tourModalStyle, modalButtonStyle, tourModalNextStyle } from '../css/theme';
import { useRTL } from '../i18n/useRTL';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowRight, KeyboardArrowLeft, Close as CloseIcon } from '@mui/icons-material';
import { useStore } from '../Zustand/store';
import { createParagraphsAndLineBreaks } from '../i18n/languageUtils';

interface ShareModalProps {
    open: boolean;
    onClose: () => void;
}

const TourModal: React.FC<ShareModalProps> = ({ open, onClose }) => {
    const [element, setElement] = useState<HTMLElement | null>(null);
    const [clicked, setClicked] = useState(false);
    const { t, i18n } = useTranslation();
    useRTL(element);
    const Arrow = i18n.dir() === 'rtl' ? KeyboardArrowLeft : KeyboardArrowRight;

    const backgroundPosition = i18n.dir() === 'ltr' 
    ? 'calc(50% + 262px) calc(50% + 125px)' 
    : 'calc(50% - 262px) calc(50% + 125px)';

    const { startTour } = useStore(state => ({
        startTour: state.startTour
    }));

    const getContent = (contentKey: string, alternateContentKey?: string) => (
        createParagraphsAndLineBreaks(t(clicked && alternateContentKey ? alternateContentKey : contentKey))
    );

    const handleButtonClick = () => {
        startTour();
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="tour-modal-title"
            aria-describedby="tour-modal-description"
        >
            <Box sx={tourModalStyle(i18n.dir())} ref={setElement}>
                <div className="tour-modal-background-image" />
                <Box className="tour-modal-title">
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        className="closeButton"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={omhTitleStyle} gutterBottom>
                        {getContent('TourModal.welcome', 'TourModal.detailedTitle')}
                    </Typography>
                </Box>
                <Typography className='tour-modal-subtitle' gutterBottom>
                    {getContent('TourModal.shortExplanation', 'TourModal.detailedExplanation')}
                </Typography>
                <Button sx={modalButtonStyle} onClick={handleButtonClick}>
                    <div className="button-text">{t('TourModal.startButton')}</div>
                    <Arrow className="chevron-left" />
                </Button>
                <div className='tour-modal-bottom-text'>
                    <Typography sx={tourModalNextStyle} component="span" onClick={() => setClicked(!clicked)}>
                        {!clicked
                            ? <><span className="firstWordStyle">{t('TourModal.readMorefirstWord')}</span> {t('TourModal.readMore')}</>
                            : <span>{t('TourModal.readMoreAlternate')}</span>
                        }
                    </Typography>
                </div>
            </Box>
        </Modal>
    );
};

export default TourModal;
