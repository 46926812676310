import { PostProps } from "./PostProps";

type FilterCriterion = string | number | boolean | null | RegExp | Array<string | number | boolean | null> | ((value: any) => boolean);

export type FilterCriteria = {
    [key in keyof PostProps]?: FilterCriterion;
};
export function filterPosts(list: PostProps[], criteria: FilterCriteria): PostProps[] {
    return list.filter((post) => filterItem(post, criteria));
}

function filterItem(item: PostProps, criteria: FilterCriteria): boolean {
    return Object.keys(criteria).every(key => {
        const filterValue = criteria[key as keyof FilterCriteria];
        const itemValue = item[key as keyof PostProps];

        if (filterValue === undefined) {
            return true;
        }

        if (typeof filterValue === 'function') {
            return filterValue(itemValue);
        } else if (filterValue instanceof RegExp) {
            return typeof itemValue === 'string' && filterValue.test(itemValue);
        }
        else if (Array.isArray(filterValue)) {
            return filterValue.includes(itemValue);
        }
        else {
            return itemValue === filterValue;
        }
    });
}
