import { createTheme } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/system';
import i18n from '../i18n/i18n';

const createMyTheme = (direction: 'ltr' | 'rtl') => {
    return createTheme({
        palette: {
            primary: {
                main: '#3A3CC9',
                contrastText: '#FFFFFF',
            },
            background: {
                default: 'rgba(255, 255, 255, 0.5)',
                paper: 'rgba(255, 255, 255, 0.7)',
            },
            text: {
                primary: '#232323',
                secondary: 'rgba(58, 60, 201, 0.8)',
            },
            divider: 'rgba(58, 60, 201, 0.6)',
        },
        typography: {
            fontFamily: ['Rubik', 'Kanit', 'sans-serif'].join(','),
            h1: {
                fontFamily: 'Kanit',
                fontWeight: 600,
                fontSize: '40px',
                lineHeight: 1,
                textAlign: 'center',
                letterSpacing: '0.03em',
                color: '#3A3CC9',
                width: '277px'
            },
            subtitle1: {
                fontFamily: 'Rubik',
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '24px',
                letterSpacing: '0.01em',
                color: '#3A3CC9',
                textAlign: 'center',
            },
            subtitle2: {
                fontFamily: 'Rubik',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '19px',
                letterSpacing: '0.01em',
                color: '#3A3CC9',
            },
            body2: {
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '144%',
                letterSpacing: '0.01em',
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        display: 'flex',
                    },
                    containedPrimary: {
                        backgroundColor: '#3A3CC9',
                        color: '#FFFFFF',
                        height: '40px',
                        marginTop: '16px',
                    },
                    outlinedPrimary: {
                        borderColor: '#3A3CC9',
                        color: 'rgba(58, 60, 201, 0.8)',
                    },
                    startIcon: {
                        // Assuming the icon should be on the right for RTL
                        // and on the left for LTR, using logical properties
                        marginInlineStart: '8px', // pushes the icon to the right for RTL and to the left for LTR
                        marginInlineEnd: '8px', // adds space between the icon and the text

                    },

                },
            },
            MuiFormControl: {
                styleOverrides: {
                    root: {
                        marginTop: '0px',
                        marginBottom: '0px',
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        textAlign: direction === "rtl" ? 'right' : 'left',
                        marginTop: '25px',
                        marginBottom: '-30px',
                    },
                },
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        '&[dir="rtl"]': {
                            right: 0,
                            transformOrigin: 'top right',
                        },
                        '&[dir="ltr"]': {
                            left: 0,
                            transformOrigin: 'top left',
                        },
                        color: 'rgba(58, 60, 201, 0.8)',
                        '&.Mui-focused': {
                            color: 'rgba(58, 60, 201, 0.8)',
                        },
                        '&&': {
                            transform: 'translate(0px, -6px) scale(1) !important',
                        },
                        width: '100%'
                    },

                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '& fieldset': {
                            borderColor: '#3A3CC9',
                            borderRadius: '8px',
                            borderWidth: '1px',
                            // Background color for input not focused
                            background: 'rgba(58, 60, 201, 0.05)',
                        },
                        '&:hover fieldset': {
                            borderColor: '#3A3CC9',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#3A3CC9',
                            // Background color for input when focused
                            background: 'rgba(58, 60, 201, 0.07)',
                        },
                        // Set the input text color
                        '& input': {
                            color: 'rgba(58, 60, 201, 0.8)',
                        },
                        // Set padding and gap if needed
                        padding: '8px',
                        height: '40px',
                        top: '20px',
                    },
                },
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        display: "flex",
                        width: "100%",
                        // Styles applied to the root element
                        '&.Mui-selected': {
                            backgroundColor: 'rgba(58, 60, 201, 0.1)',
                            borderRadius: '8px',
                            // Target the ListItemText for when the ListItemButton is selected
                            '& .MuiListItemText-primary': {
                                color: '#3A3CC9',
                            },
                            // Add other styles for the selected state here
                        },
                        '&: hover': {
                            borderRadius: '8px',
                        },
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    primary: {
                        // General styles
                        display: 'flex',
                        marginRight: '-5px',
                        fontFamily: 'Kanit',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '17px',
                        lineHeight: '148%',
                        // Default color
                        color: 'rgba(0, 0, 0, 0.7)',

                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    root: {
                        width: 42,
                        height: 26,
                        padding: 0,
                        '& .MuiSwitch-switchBase': {
                            padding: 0,
                            margin: 2,
                            transitionDuration: '300ms',
                            '&.Mui-checked': {
                                transform: 'translateX(16px)',
                                color: '#fff',
                                '& + .MuiSwitch-track': {
                                    backgroundColor: '#3A3CC9', // You can adjust this to use theme colors
                                    opacity: 1,
                                    border: 0,
                                },
                                '&.Mui-disabled + .MuiSwitch-track': {
                                    opacity: 0.5,
                                },
                            },
                            '&.Mui-focusVisible .MuiSwitch-thumb': {
                                color: '#33cf4d',
                                border: '6px solid #fff',
                            },
                            '&.Mui-disabled .MuiSwitch-thumb': {
                                color: 'rgba(0, 0, 0, 0.3)', // Adjust as needed
                            },
                            '&.Mui-disabled + .MuiSwitch-track': {
                                opacity: 0.7, // Adjust as needed
                            },
                        },
                        '& .MuiSwitch-thumb': {
                            boxSizing: 'border-box',
                            width: 22,
                            height: 22,
                        },
                        '& .MuiSwitch-track': {
                            borderRadius: 26 / 2,
                            backgroundColor: '#C74242', // Adjust as needed
                            opacity: 1,
                            transition: 'background-color 500ms',
                        },
                    },
                },
            },
            // ... any additional component overrides

            MuiPaper: {
                styleOverrides: {
                    root: {
                        boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.05)',
                        backdropFilter: 'blur(25px)',
                        borderRadius: '20px',
                    },
                },
            },
            // Add any additional component overrides here
        },

    })
};

export default createMyTheme;

export const modalStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '24px 28px',
    gap: '24px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.15)',
    borderRadius: '16px',

    '& .closeButton': {
        position: 'absolute',
        width: '24px',
        height: '24px',
        right: '8px',
        top: '8px',
        zIndex: 1,
    },
    '&:dir(rtl) .closeButton': {
        left: '8px',
        right: 'auto',
    },
    '& .share-modal-title': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px',
        gap: '24px',
        isolation: 'isolate',
        height: '19px',
        flex: 'none',
        alignSelf: 'stretch',
        order: 1,
        flexGrow: 0,
    },
    '& .share-modal-icons': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '24px',
        height: '85px',
        flex: 'none',
        order: 2,
        flexGrow: 0,
    },
    '& .copy-link-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: '100%',
        marginTop: '-10px',
        padding: '0px',
        flex: 'none',
        order: 3,
        flexGrow: 0,
    },
};

export const boldSubtitle: SxProps<Theme> = {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.01em',
    color: 'rgba(0, 0, 0, 0.7)',
};

export const smallTextStyle: SxProps<Theme> = {
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '17px',
    letterSpacing: '0.01em',
    color: 'rgba(0, 0, 0, 0.8)',
    flex: 'none',
    order: 1,
    flexGrow: 0,
};

export const menuStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '4px',
    gap: '4px',
    position: 'absolute',
    background: '#FFFFFF',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
    borderRadius: '6px',
    zIndex: 6,
    marginTop: '-5px', // Additional margin at the top
    '.MuiMenu-list': {
        paddingTop: 0,
        paddingBottom: 0
    }

};

export const menuItemHoverStyle: SxProps<Theme> = {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 16px 0px 24px',
    gap: '16px',
    width: '200px',
    height: '40px',
    borderRadius: '8px',
    order: 1,
    flexGrow: 0,
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '148%', // This sets the line height to 148% of the font size
    color: 'rgba(0, 0, 0, 0.7)',
    '&:hover': {
        background: 'rgba(58, 60, 201, 0.1)',
        borderRadius: '4px',
    }
};

export const omhTitleStyle: SxProps<Theme> = {
    fontFamily: ['Rubik', 'Kanit', 'sans-serif'].join(','),
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: 1,
    textAlign: 'center',
    letterSpacing: '0.03em',
    color: '#3A3CC9',
}
export const tourModalStyle = (direction: 'ltr' | 'rtl'): SxProps<Theme> => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '32px 40px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    gap: '15px',
    transform: 'translate(-50%, -50%)',
    isolation: 'isolate',
    width: '670px',
    height: '440px',
    flex: 'none',
    background: '#FFFFFF',
    boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.15)',
    borderRadius: '10px',
    order: 0,
    flexGrow: 0,
    zIndex: 0,
    '& .closeButton': {
        position: 'absolute',
        width: '24px',
        height: '24px',
        left: '8px',
        top: '8px',
        zIndex: 1,
    },
    '& .tour-modal-subtitle': {
        width: '500px',
        minHeight: '87px',
        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16.5px',
        lineHeight: '144%',
        color: '#3A3CC9',
        flex: 'none',
        order: 2,
        flexGrow: 0,
    },
    '& .tour-modal-background-image': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.04,
        width: '100%',
        height: '100%',
        backgroundImage: `url('/1MH_logo_svg.svg')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: direction === 'rtl' ? 'calc(50% - 262px) calc(50% + 125px)' : 'calc(50% + 262px) calc(50% + 125px)',
        backgroundSize: '50%',
        zIndex: -1,
    },
    '& .tour-modal-bottom-text': {
        width: '100%',
        height: '100%',
        order: 3,
        flexDirection: 'column',
        display: 'flex',
    },

});

export const modalButtonStyle: SxProps<Theme> = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 30px 10px 25px',
    gap: '8px',
    background: '#3A3CC9',
    borderRadius: '10px',
    order: 3,
    '& .chevron-left': { // Style for the chevron-left icon
        width: '24px',
        height: '24px',
        flex: 'none',
        color: 'white',
        order: 2,
        flexGrow: 0,
    },
    '& .icon-frame': { // Style for the frame around the icon
        width: '20px',
        height: '20px',
        left: '2px',
        top: '2px',
    },
    '& .icon-stroke': { // Style for the icon stroke
        left: '25%',
        right: '35%',
        top: '15%',
        bottom: '15%',
        background: '#FFFFFF',
    },
    '& .button-text': { // Style for the button text

        fontFamily: 'Rubik',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '28px',
        textAlign: 'right',
        color: '#FFFFFF',
        flex: 'none',
        order: 1,
        flexGrow: 0,
        overflow: 'hidden',
    },
    '&:hover': {
        backgroundColor: '#3237A2',
    },
};
export const tourModalNextStyle: SxProps<Theme> = {

    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '144%',
    color: '#3A3CC9B2',
    order: 3,
    flexGrow: 0,
    zIndex: 1,
    userSelect: 'none',
    cursor: 'pointer',
    display: 'inline-block',
    marginTop: 'auto',
    '& .firstWordStyle': {
        color: '#3A3CC9',
        fontWeight: 600,
    }
};

const cardheaderStyle: SxProps<Theme> = {
    fontFamily: 'Rubik',
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.01em',
    textAlign: 'left',
};

export const cardHeaderUsername: SxProps<Theme> = {
    ...cardheaderStyle,
    fontWeight: 500,
    color: '#232323',
};

export const cardHeaderDate: SxProps<Theme> = {
    ...cardheaderStyle,
    fontWeight: 400,
    color: 'rgba(35, 35, 35, 0.8)',
};