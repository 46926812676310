import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import { NavLink } from "react-router-dom";
import "../css/noBorderSelect.css";
import "./Header.css";
import MyAccountNav from "./MyAccountNav";
import ProtectedComponent from "../Router/ProtectedComponent";
import { useRTL } from "../i18n/useRTL";
import { MAIN_ROUTE } from "../Router/AppRoutes";
import LogoutComponent from "./LogoutComponent";

const Header: React.FC = () => {
  const { t } = useTranslation();
  const [element, setElement] = useState<HTMLElement | null>(null);
  useRTL(element);

  return (
    <header className="header" ref={setElement}>
      <ProtectedComponent component={LogoutComponent} restrictedRoutes={MAIN_ROUTE} />
      <LanguageSwitcher />
      <ProtectedComponent component={MyAccountNav} restrictedRoutes={MAIN_ROUTE} />
      <NavLink to="/aboutus" className="about_us">
        {t("Header.aboutUs")}
      </NavLink>
      <div className="header_logo">
        <NavLink to={MAIN_ROUTE} className="">
          <img src="/1MH_logo_svg.svg" alt="Logo" />
        </NavLink>
      </div>
    </header>
  );
};

export default Header;