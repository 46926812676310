import React, { useState, useEffect } from 'react';
import { useStore } from '../Zustand/store';
import { FormControl, Select, MenuItem, OutlinedInput, ThemeProvider, SelectChangeEvent, InputLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme from '../Header/LanguageSwitcherTheme';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

const LanguageFilter: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { getLanguages, updateFilterCriteria } = useStore(state => ({
        getLanguages: state.getLanguages,
        updateFilterCriteria: state.updateFilterCriteria
    }));
    const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
    const languages = getLanguages();

    useEffect(() => {
        updateFilterCriteria({ language: selectedLanguages.length > 0 ? selectedLanguages : undefined });
    }, [selectedLanguages, updateFilterCriteria]);

    const handleLanguageChange = (event: SelectChangeEvent<typeof selectedLanguages>) => {
        setSelectedLanguages(event.target.value as string[]);
    };

    const formControlStyle: SxProps = {
        m: 1,
        minWidth: 120,
        width: 'auto',
        marginRight: i18n.dir() === 'rtl' ? '15' : 'auto',
        marginLeft: i18n.dir() === 'rtl' ? 'auto' : '15',
        textAlign: 'center'
    }
    const flexContainerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
        alignItems: 'center',
        width: '100%',
    };

    return (
        <ThemeProvider theme={theme}>
            <div style={flexContainerStyle}>
                <InputLabel htmlFor="language-select" style={{
                    marginRight: i18n.dir() === 'rtl' ? '10px' : '-10px',
                    marginLeft: i18n.dir() === 'rtl' ? '-10px' : '10px'
                }}>
                    {t('LanguageFilter.label')}
                </InputLabel>
                <FormControl sx={formControlStyle} size="small">
                    <Select
                        labelId="language-select-label"
                        id="language-select"
                        multiple
                        displayEmpty
                        value={selectedLanguages}
                        onChange={handleLanguageChange}
                        input={<OutlinedInput />}
                        renderValue={(selected) => selected.length > 0 ? selected.join(', ') : <em>{t('LanguageFilter.default')}</em>}
                        inputProps={{ 'aria-label': 'Without label' }}
                        IconComponent={ExpandMoreIcon}
                        className='noBorderSelect'
                    >
                        <MenuItem disabled value="" sx={{justifyContent:'center'}} >
                            <em>{t('LanguageFilter.default')}</em>
                        </MenuItem>
                        {languages.map((language) => (
                            <MenuItem className='languageMenuItem' key={language} value={language}>
                                {language}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
        </ThemeProvider>
    );
};

export default LanguageFilter;
