import React from 'react';
import NavigationMenu from './Navigation/NavigationMenu';
import ContentGrid from './ContentGrid';
import { useTranslation } from 'react-i18next';
import './MainPage.css';
import LanguageFilter from '../../Components/LanguageFilter';
import { Grid as LoaderGrid } from 'react-loader-spinner';
import { useStore } from '../../Zustand/store';
import { usePosts } from '../../Logic/Hooks/usePosts';

const MainPage: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { platform, isProIsrael } = useStore(state => ({
        platform: state.platform,
        isProIsrael: state.isProIsrael,
    }));

    const { loadedPosts, error } = usePosts(platform, isProIsrael);

    const mainPageClasses = `MainPage ${i18n.dir() === 'ltr' ? 'MainPageRowReverse' : ''}`;
    const languageFilterClasses = `languageFilter ${i18n.dir() === 'ltr' ? 'left' : 'right'}`;
    const errorStyle = {
        color: 'red',
        fontWeight: 'bold'
    };
    return (
        <div className={mainPageClasses}>

            <div className="ContentArea">
                {loadedPosts ? (
                    <>
                        <LanguageFilter />
                        <ContentGrid />
                    </>
                ) : (
                    <div className="loading-grid">
                        {!error && <LoaderGrid
                            visible={true}
                            height="80"
                            width="80"
                            color="#4fa9bd"
                            ariaLabel="grid-loading"
                            radius="12.5"
                            wrapperStyle={{}}
                            wrapperClass="grid-wrapper"
                        />}
                        <p style={error ? errorStyle : {}}>{error ? t('MainPage.errorLoading') : t('MainPage.loading')}</p>
                    </div>
                )}
            </div>
            <div className='NavigationMenu'>
                <NavigationMenu />
            </div>
        </div>
    );
};

export default MainPage;
