// ShareModal.tsx
import React, { useState } from 'react';
import { Modal, Box, IconButton, Typography, Divider, Paper, InputBase } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { modalStyle, boldSubtitle, smallTextStyle } from '../css/theme';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { PostProps } from './PostProps';
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    XIcon,
    LinkedinShareButton,
    LinkedinIcon,
} from 'react-share';
import { useTranslation } from 'react-i18next';
import { useRTL } from '../i18n/useRTL';
interface ShareModalProps {
    open: boolean;
    onClose: () => void;
    post: PostProps;
    onCopy: () => void;
}

const ShareModal: React.FC<ShareModalProps> = ({ open, onClose, post, onCopy }) => {
    const { t } = useTranslation()
    const title = "template";
    const radius = 15;
    const [element, setElement] = useState<HTMLElement | null>(null);
    useRTL(element);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(post.post_url).then(() => {
            onCopy()
        }, () => {
            // Handle error
        });
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="share-modal-title"
            aria-describedby="share-modal-description"
        >
            <Box sx={modalStyle} ref={setElement}>
                <Box className="share-modal-title">
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        className="closeButton"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={boldSubtitle}>
                        {t("ShareModal.title")}
                    </Typography>
                </Box>
                <Box className="share-modal-icons" sx={{ alignSelf: 'center' }}>
                    <FacebookShareButton url={post.post_url}>
                        <FacebookIcon size={60} borderRadius={radius} />
                        <Typography sx={smallTextStyle}>
                            Facebook
                        </Typography>
                    </FacebookShareButton>

                    <TwitterShareButton url={post.post_url} title={title}>
                        <XIcon size={60} borderRadius={radius} />
                        <Typography sx={smallTextStyle}>
                            X
                        </Typography>
                    </TwitterShareButton>

                    <LinkedinShareButton url={post.post_url}>
                        <LinkedinIcon size={60} borderRadius={radius} />
                        <Typography sx={smallTextStyle}>
                            LinkedIn
                        </Typography>
                    </LinkedinShareButton>
                </Box>
                <Box className="copy-link-container">
                    <Paper
                        component="form"
                        variant='outlined'
                        dir='ltr'
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 400,
                            borderRadius: '8px',
                            borderWidth: '1px',
                            borderColor: '#393bc94d',
                            background: 'rgba(58, 60, 201, 0.05)',
                            boxShadow: 'none',
                        }}
                    >
                        <InputBase
                            fullWidth
                            readOnly
                            defaultValue={post.post_url}
                            sx={{
                                ml: 1,
                                flex: 1,
                                color: 'rgba(58, 60, 201, 0.8)',
                            }}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton color="primary" sx={{ p: '10px' }} aria-label="copy" onClick={handleCopyLink}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Paper>
                </Box>
            </Box>
        </Modal >
    );
};

export default ShareModal;
