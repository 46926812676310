import React, { ElementType } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

interface ProtectedComponentProps {
    component: ElementType;
    restrictedRoutes?: string | string[];
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({ component: Component, restrictedRoutes }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();

    if (!isAuthenticated) {
        return null;
    }

    const isRestrictedRoute = Array.isArray(restrictedRoutes)
        ? restrictedRoutes.includes(location.pathname)
        : location.pathname === restrictedRoutes;

    if (restrictedRoutes && !isRestrictedRoute) {
        return null;
    }

    return <Component />;
};

export default ProtectedComponent;
