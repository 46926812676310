// src/hooks/usePosts.ts
import { useState, useEffect } from 'react';
import { PlatformType, useStore } from '../../Zustand/store';
import { getPosts } from '../services';
import { IPostData, PostData } from '../../Post/PostData';


export const usePosts = (platform: PlatformType, isProIsrael: boolean) => {
    const [loadedPosts, setLoadedPosts] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const setAllPosts = useStore(state => state.setAllPosts);
    const updateFilteredPosts = useStore(state => state.updateFilteredPosts);
    const updateFilterCriteria = useStore(state => state.updateFilterCriteria);

    useEffect(() => {
        const fetchAndSetPosts = async () => {
            try {
                setError(null);
                setLoadedPosts(false);
                updateFilterCriteria({ language: undefined });
                const postsData = await getPosts(platform);
                const posts = postsData.data.data.map((postData: IPostData) => new PostData(postData));
                setAllPosts(posts);
                updateFilteredPosts();
                setLoadedPosts(true);
            } catch (error: any) {
                console.error("Failed to fetch and set posts:", error.message);
                setError(error.message);
                setLoadedPosts(false);
            }
        };

        fetchAndSetPosts();
    }, [platform, isProIsrael, setAllPosts, updateFilteredPosts, updateFilterCriteria]);

    return { loadedPosts, error };
};
