import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Container, Box, Button, Typography, Divider, Paper, ThemeProvider } from '@mui/material';
import { Google as GoogleIcon, Facebook as FacebookIcon } from '@mui/icons-material';
import CustomInput from './CustomInput';
import { useRTL } from '../i18n/useRTL';
import { validateEmail } from '../Logic/validation';
import { submitLogin } from '../Logic/services';
import { MAIN_ROUTE } from '../Router/AppRoutes';
import "./SignIn.css"
import { useAxios } from '../Logic/Hooks/useAxios';
import createMyTheme from '../css/theme';
import { useCookies } from 'react-cookie';

const SignIn: React.FC = () => {

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const { execute, isLoading, response, error } = useAxios(submitLogin);
  const [cookies, setCookie, removeCookie] = useCookies(['authFlag']);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [element, setElement] = useState<HTMLElement | null>(null);
  useRTL(element);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateEmail(formData.email) || formData.password.length === 0) {
      return;
    }
    execute(formData)
  };

  useEffect(() => {
    if (response !== null) {
      console.log("Attempting to navigate to:", MAIN_ROUTE);
      setCookie('authFlag', 'true', { path: '/' });
      navigate(MAIN_ROUTE);
    }
  }, [response, navigate]);

  const theme = createMyTheme(i18n.dir() as 'ltr' | 'rtl');

  return (
    <div>
      <Container maxWidth="sm" ref={setElement}>
        <ThemeProvider theme={theme}>
          <Paper className='form-container' >
            <Box className='form-title'>
              <Typography className='form-title-omh' variant="h1" gutterBottom>
                One Million Hands
              </Typography>
              <Typography className='form-subtitle' variant="subtitle1" gutterBottom>
                {t('SignIn.subtitle')}
              </Typography>
            </Box>
            <Box
              className="login-box"
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <CustomInput
                name={"email"}
                label={t('SignIn.email_label')}
                placeholder={t('SignIn.email_input')}
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                validate={validateEmail}
              />
              <CustomInput
                name={"password"}
                label={t('SignIn.password_label')}
                labelContent={<NavLink to="/forgot-password" tabIndex={-1} style={{ fontSize: '0.75rem', color: '#546e7a'}}>{t('SignIn.forgot_password')}</NavLink>}
                placeholder={t('SignIn.password_input')}
                type="password"
                value={formData.password}
                onChange={handleInputChange}
                autoComplete="off"
              />
              <Box sx={{ width: "100%" }}>
                <Button variant="contained" color="primary" fullWidth type="submit">
                  {t('SignIn.login_button')}
                </Button>
                <Typography
                  gutterBottom
                  style={{
                    color: isLoading ? 'blue' : error ? 'red' : 'green',
                    textAlign: 'center'
                  }}>
                  {isLoading ? t('SignIn.loading_message') : error ? t('SignIn.error_message') : response ? t('SignIn.login_message') : ""}
                </Typography>
              </Box>
            </Box>
            {/* <Divider className='divider'>{t('SignIn.divider')}</Divider>
            <Box className="special-login">
              <Button variant="outlined" startIcon={<GoogleIcon />} fullWidth >
                {t('SignIn.google_login')}
              </Button>
              <Button variant="outlined" startIcon={<FacebookIcon />} fullWidth sx={{ mt: 1 }}>
                {t('SignIn.facebook_login')}
              </Button>
            </Box> */}
            <Box className="signup-text">
              <Typography variant="body2" sx={{ mt: 2 }}>
                {t('SignIn.signup_prompt')}
              </Typography>
              <Typography variant="body2">
                <NavLink to="/signup" className="signup" >
                  {t('SignIn.signup_button')}
                </NavLink></Typography>
            </Box>
          </Paper>
        </ThemeProvider>
      </Container>
    </div>
  );
};

export default SignIn;