import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import { validateToken } from '../Logic/services';
import { useCookies } from 'react-cookie';

interface AuthContextType {
    isAuthenticated: boolean;
    checkAuthStatus: () => void;
}

const defaultAuthContext: AuthContextType = {
    isAuthenticated: false,
    checkAuthStatus: () => {},
};

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [cookies, setCookie, removeCookie] = useCookies(['authFlag']);

    const checkAuthStatus = async () => {
        if (cookies.authFlag) { // Check for client-accessible cookie
            try {
                await validateToken();
                setIsAuthenticated(true);
            } catch (error) {
                console.error('Token validation error:', error);
                setIsAuthenticated(false);
                removeCookie('authFlag'); // Remove cookie if validation fails
            }
        }
    };

    useEffect(() => {
        checkAuthStatus();
    }, [cookies.authFlag]); // Dependency on the authFlag cookie

    return (
        <AuthContext.Provider value={{ isAuthenticated, checkAuthStatus }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext) as AuthContextType;
