import React, { useState } from 'react';
import { FormControl, InputLabel, TextField, TextFieldProps, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

type CustomInputProps = TextFieldProps & {
  label: string;
  labelContent?: React.ReactNode;
  validate?: (value: string) => { isValid: boolean; message: string };
};

const CustomInput: React.FC<CustomInputProps> = ({
  label,
  labelContent,
  validate,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleBlur = () => {
    const value = props.value as string;
    if (value.trim() === '') {
      setError(false);
      setErrorMessage('');
    } else if (validate) {
      const validationResult = validate(value);
      setError(!validationResult.isValid);
      setErrorMessage(validationResult.isValid ? '' : t(validationResult.message));
    }
  };

  return (
    <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel shrink={true} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', transform: 'translate(14px, -6px) scale(1)' }}>
        {label}*
        {labelContent && <span>{labelContent}</span>}  
      </InputLabel>
      <Tooltip
        title={error ? errorMessage : ''}
        placement="bottom"
        arrow
        PopperProps={{
          style: {
            textAlign: 'center',
            direction: i18n.dir() 
          },
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
            {
              name: 'flip',
              enabled: false,
            },
          ],
        }}
      >
        <TextField
          {...props}
          error={error || props.error}
          onBlur={(e) => {
            handleBlur();
            if (props.onBlur) props.onBlur(e);
          }}
        />
      </Tooltip>
    </FormControl>
  );
};

export default CustomInput;
