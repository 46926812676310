import React, { ElementType, useEffect } from 'react';
import {Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

interface ProtectedRouteProps {
    element: ElementType;
}

const ProtectedRoute = ({ element: Component }: ProtectedRouteProps) => {
    const { isAuthenticated, checkAuthStatus } = useAuth();
    const location = useLocation();
    
    useEffect(() => {
        if (!isAuthenticated) {
            checkAuthStatus();
        }
    }, [isAuthenticated, checkAuthStatus]);

    return isAuthenticated 
        ? <Component />
        : <Navigate to="/" state={{ from: location }} replace />;
};

export default ProtectedRoute;
