import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Unstable_Grid2 as Grid, Snackbar, Alert, Typography } from '@mui/material';
import Post from '../../Post/Post';
import { useStore } from '../../Zustand/store'; // Adjust the path as necessary
import ShareModal from '../../Post/ShareModal';
import { PostProps } from '../../Post/PostProps';
import { Height } from '@mui/icons-material';

const PostsList = () => {
    const { t, i18n } = useTranslation()
    const filteredPosts = useStore((state) => state.filteredPosts);
    const [currentPostForShare, setCurrentPostForShare] = useState<PostProps | null>(null);
    const [isShareModalOpen, setShareModalOpen] = useState(true);

    const handleShareClick = (post: PostProps) => {
        setCurrentPostForShare(post);
        setShareModalOpen(true);
    };

    const handleCloseModal = () => {
        setShareModalOpen(false);
        setCurrentPostForShare(null);
    };

    const [toastOpen, setToastOpen] = React.useState(false);
    const handleOpenSnackbar = () => {
        setToastOpen(true);
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setToastOpen(false);
    };


    return (
        filteredPosts.length !== 0 ? <>
            {filteredPosts.map((post, index) => (
                <Grid key={`${post.platform}-${index}-${post.pro_israel}`}>
                    <Post index={index} {...post} onShareClick={handleShareClick} />
                </Grid> /* TODO: Display message when there are no posts */

            ))}
            {currentPostForShare && (
                <ShareModal
                    open={isShareModalOpen}
                    onClose={handleCloseModal}
                    post={currentPostForShare}
                    onCopy={handleOpenSnackbar}
                />
            )}
            <Snackbar
                open={toastOpen}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} // Position at bottom-left
            >
                <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                    <Typography dir={i18n.dir()}>
                        {t("ShareModal.copyAlert")}
                    </Typography>
                </Alert>
            </Snackbar>
        </> :
            <p>no posts found</p>
    );
};

const ContentGrid: React.FC = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className='grid'>
            <Box sx={{ flexGrow: 1 }} style={{ display: "flex", marginTop: "0px", marginRight: "20px", marginBottom: "20px", marginLeft: "20px", justifyContent: "center", alignItems: "center" }}>
                <Grid maxWidth={"1300px"} container display="flex" justifyContent="center" alignItems="center" rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <PostsList />
                </Grid>
            </Box>
        </div>
    );
};

export default ContentGrid;

// const postData = {
//     userName: "Chef Ramsey", // Changed from title to userName
//     date: "September 14, 2016", // Changed from subheader to date
//     avatarLabel: "R",
//     imageUrl: "/static/images/cards/paella.jpg",
//     likeCount: 250, // Added like count
//     contentText: "This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.",
//     additionalContent: (
//         <>
//             <p>Method:</p>
//             <p>Heat 1/2 cup of the broth in a pot until simmering, add saffron and set aside for 10 minutes.</p>
//             <p>Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over medium-high heat. Add chicken, shrimp and chorizo, and cook, stirring occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a large plate and set aside, leaving chicken and chorizo in the pan. Add pimentón, bay leaves, garlic, tomatoes, onion, salt and pepper, and cook, stirring often until thickened and fragrant, about 10 minutes. Add saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.</p>
//             <p>Add rice and stir very gently to distribute. Top with artichokes and peppers, and cook without stirring, until most of the liquid is absorbed, 15 to 18 minutes. Reduce heat to medium-low, add reserved shrimp and mussels, tucking them down into the rice, and cook again without stirring, until mussels have opened and rice is just tender, 5 to 7 minutes more. (Discard any mussels that don’t open.)</p>
//             <p>Set aside off of the heat to let rest for 10 minutes, and then serve.</p>
//         </>
//     )
// };


// const extractPostData = (data: any): PostProps => {
//     return {
//         userName: data.creator_name,
//         date: data.created_at,
//         likeCount: data.number_of_likes,
//         contentText: data.content,
//         imageUrl: data.creator_image,
//         pro_israel: data.pro_israel,
//     };
// };