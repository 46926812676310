import { PostData } from "./PostData";

export interface IPostProps {
    postId: string;
    platform: string;
    post_url: string;
    userName: string;
    date: string | null;
    likeCount: number | null;
    contentText: string | null;
    imageUrl: string;
    pro_israel: boolean;
    language: string;
}

export class PostProps implements IPostProps {
    postId: string;
    platform: string;
    post_url: string;
    userName: string;
    date: string | null;
    likeCount: number | null;
    contentText: string | null;
    imageUrl: string;
    pro_israel: boolean;
    language: string;

    constructor(data: IPostProps) {
        this.postId = data.postId;
        this.platform = data.platform;
        this.post_url = data.post_url;
        this.userName = data.userName;
        this.date = data.date;
        this.likeCount = data.likeCount;
        this.contentText = data.contentText;
        this.imageUrl = data.imageUrl;
        this.pro_israel = data.pro_israel;
        this.language = data.language;
    }

    static fromPostData(postData: PostData): PostProps {
        const platformNameMap: { [key: string]: string } = {
            INSTGRAM: "Instagram",
            FACEBOOK: "Facebook",
            TWITTER: "Twitter",
            TIKTOK: "TikTok",
            LINKEDIN: "LinkedIn",
            YOUTUBE: "YouTube"
        };
        return new PostProps({
            postId: postData.postId,
            platform: platformNameMap[postData.platform] || postData.platform,
            post_url: postData.url,
            userName: postData.creatorName,
            date: postData.publishDate,
            likeCount: postData.numberOfLikes,
            contentText: postData.content,
            imageUrl: "", 
            pro_israel: postData.sentiment === "1",
            language: postData.language
        });
    }
}

export interface IExtendedPostProps extends IPostProps {
    index?: number;
    onShareClick?: (data: PostProps) => void;
}

export class ExtendedPostProps extends PostProps implements IExtendedPostProps {
    index?: number;
    onShareClick?: (data: PostProps) => void;

    constructor(data: IExtendedPostProps) {
        super(data); // Initialize the base class properties
        this.index = data.index;
        this.onShareClick = data.onShareClick;
    }
}