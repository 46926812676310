import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PlatformType, useStore } from '../../../Zustand/store';

type ListButtonProps = {
    platform: string;
    onClick: (platform: string) => void;
    isSelected: boolean;
};

const ListButton: React.FC<ListButtonProps> = ({ platform, onClick, isSelected }) => {
    const imageUrl = `/Social_Icons/${platform}_Icon.svg`;

    return (
        <ListItem disablePadding>
            <ListItemButton selected={isSelected} onClick={() => onClick(platform)}>
                <ListItemIcon className='nav-button-icon'>
                    <img id={`${platform}_icon`} src={imageUrl} alt={`${platform} icon`} />
                </ListItemIcon>
                <ListItemText primary={platform} className='nav-button-text' />
            </ListItemButton>
        </ListItem>
    );
};

const PlatformNavigation: React.FC = () => {
    const { t } = useTranslation();
    const [selectedPlatform, setSelectedPlatform] = useState<string | null>("Instagram");
    const setPlatform = useStore((state) => state.setPlatform);

    useEffect(() => {
        if (selectedPlatform) {
            setPlatform(selectedPlatform as PlatformType);
        }
    }, [selectedPlatform]);

    const handleClick = (platform: string) => {
        setSelectedPlatform(platform);
    };

    const platforms = ["Instagram", "Facebook", "Twitter", "TikTok", "LinkedIn", "YouTube"];

    return (
        <Box className="navPlatformList">
            <Typography variant='subtitle2'>{t("Navigation.Platform.title")}:</Typography>
            <List className='' component="nav">
                {platforms.map((platform) => (
                    <ListButton
                        key={platform}
                        platform={platform}
                        onClick={handleClick}
                        isSelected={selectedPlatform === platform}
                    />
                ))}
            </List>
        </Box>
    );
};

export default PlatformNavigation;
