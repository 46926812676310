import { SxProps, Theme } from '@mui/system';
import { Styles } from 'react-joyride';

// Common font style
const commonFontStyle: SxProps<Theme> = {
  fontFamily: 'Rubik',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '15px',
  lineHeight: '122%', // or '23px'
  color: '#FFFFFF',
  order: 0,
  flexGrow: 0,
  zIndex: 0,
};

export const ToolTipText: SxProps<Theme> = commonFontStyle;

export const CustomButtonStyle: SxProps<Theme> = {
  ...commonFontStyle, // Include common font styles
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 16px',
  gap: '8px',
  minWidth: '63px',
  minHeight: '40px',
  background: '#2E30AF',
  borderRadius: '4px',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: 'none'
  }
};

export const CustomBackButtonStyle: SxProps<Theme> = {
  ...commonFontStyle, // Include common font styles
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 16px',
  gap: '8px',
  minWidth: '63px',
  minHeight: '40px',
  borderRadius: '4px',
  background: 'none',
  border: 'none',
  boxShadow: 'none', // No box shadow
  backdropFilter: 'none', // No backdrop filter
  '&:hover': {
    background: 'rgba(58, 60, 201, 0.5)',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Smaller box shadow on hover
  },
};

export const customBoxStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  gap: '20px',
  maxWidth: '360px',
  minWidth: '260px',
  isolation: 'isolate',
  background: 'rgba(58, 60, 201, 0.7)',
  boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.15)',
  borderRadius: '4px',
};

export const joyrideOutline: Partial<Styles> = {
  options: {
  },
  spotlight: {
    borderRadius: '15px',
    border: '4px solid #3A3CC9',
    margin: "-4px",
    zIndex: 1001,
  }
}