import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: '4px', // Or any specific value you need
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          borderRadius: '4px !important',
          fontFamily: ['Rubik', 'Kanit', 'sans-serif'].join(','),
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '148%',
          alignItems: 'center',
          PaddingTop: '10px !important',
          PaddingBottom: '10px !important',
          color: 'rgba(0, 0, 0, 0.7)',
          '&.languageMenuItem': {
            '&.Mui-selected': {
              backgroundColor: '#3A3CC91A !important',
            },
          },
        },
      },
    },
  },
});

export default theme;
