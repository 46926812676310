import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAxios } from '../Logic/Hooks/useAxios';
import { submitLogout } from '../Logic/services';
import { useAuth } from '../Router/AuthContext';
import { useCookies } from 'react-cookie';
import { MAIN_ROUTE } from "../Router/AppRoutes";
const LogoutComponent: React.FC = () => {
  const navigate = useNavigate();
  const { checkAuthStatus } = useAuth();
  const [cookies, setCookie, removeCookie] = useCookies(['authFlag']);
  const {
    execute: logout,
    isLoading, 
    response, 
    error,
    statusCode
  } = useAxios<void, void>(submitLogout);
  
  const { t } = useTranslation();

  useEffect(() => {
    if (statusCode === 200) {
      removeCookie('authFlag', { path: '/' });
      checkAuthStatus();
      navigate(MAIN_ROUTE);
      console.log("navigate")
    }
  }, [statusCode]);

  const handleLogoutClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    logout();  // This might need arguments if your API requires them
  };

  return (
    <NavLink to="/" onClick={handleLogoutClick} className="logout-link">
      {t("Header.logout")}
    </NavLink>
  );
};

export default LogoutComponent;
