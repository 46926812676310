import React, { useState } from 'react';
import { ThemeProvider, Box } from '@mui/material';
import './NavigationMenu.css';
import HelpButton from './HelpButton';
import ModeSwitch from './ModeSwitch';
import PlatformNavigation from './PlatformNavigation';
import { useRTL } from '../../../i18n/useRTL';
import createMyTheme from '../../../css/theme';
import { useTranslation } from 'react-i18next';

const NavigationMenu: React.FC = () => {
    const { i18n } = useTranslation();
    const [element, setElement] = useState<HTMLElement | null>(null);
    useRTL(element);
    const theme = createMyTheme(i18n.dir() as 'ltr' | 'rtl');
    return (
        <div className="navigation" ref={setElement}>
            <ThemeProvider theme={theme}>
                <Box className='modeSwitcher'>
                    <ModeSwitch />
                </Box>
                <PlatformNavigation />
            </ThemeProvider>
            <HelpButton />
        </div>
    );
};

export default NavigationMenu;
