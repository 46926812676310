export interface IPostData {
    postId: string;
    publishDate: string;
    url: string;
    location: string;
    description: string;
    content: string;
    type: string;
    sentiment: string;
    language: string;
    viralityScore: number;
    platform: string;
    numberOfLikes: number;
    numberOfViews: number;
    creatorName: string;
    creatorUrl: string;
    creatorId: string;
}

export class PostData implements IPostData {
    postId: string = '';
    publishDate: string = '1970-01-01T00:00:00.000Z';
    url: string = '';
    location: string = '';
    description: string = '';
    content: string = '';
    type: string = '';
    sentiment: string = '';
    language: string = '';
    viralityScore: number = 1;
    platform: string = ''
    numberOfLikes: number = 0;
    numberOfViews: number = 0;
    creatorName: string = '';
    creatorUrl: string = '';
    creatorId: string = '';

    constructor(data: IPostData) {
        Object.assign(this, data);
    }
}

export function parsePostDataList(jsonList: string): PostData[] {
    const rawData = JSON.parse(jsonList);
    
    if (!Array.isArray(rawData)) {
        throw new Error('Input JSON is not an array.');
    }

    const postDataList = rawData.map((data) => new PostData(data as IPostData));
    
    return postDataList;
}